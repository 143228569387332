import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

import Hero from "../components/librarySpace/Hero"
import Intro from "../components/librarySpace/Intro"
import SliderLightbox from "../components/librarySpace/SliderLightbox"
import FeaturedImage from "../components/librarySpace/FeaturedImage"
import MoreInformationLinks from "../components/librarySpace/MoreInformationLinks"

const librarySpace = props => {
  const { seoInfo } = props.data
  const hero = props?.data?.hero?.template?.librarySpace
  const intro = props?.data?.intro?.template?.librarySpace
  const sliderLightbox = props?.data?.sliderLightbox?.template?.librarySpace
  const featuredImg = props?.data?.featuredImg?.template?.librarySpace
  const moreImformation = props?.data?.moreImformation?.template?.librarySpace

  return (
    <Layout>
      <Seo
        title={seoInfo.seoFields.swbThemeMetaTitle}
        description={seoInfo.seoFields.swbThemeDescription}
        //metaImg={seoInfo.seoFields.swbThemeImage.localFile.relativePath}
        location={props.location.pathname}
      />
      <Hero data={hero} />
      <Intro data={intro} />
      <SliderLightbox data={sliderLightbox} />
      <FeaturedImage data={featuredImg} />
      <MoreInformationLinks data={moreImformation} />
    </Layout>
  )
}

export const librarySpaceQuery = graphql`
  query librarySpaceTempPage($id: String!) {
    seoInfo: wpPage(id: { eq: $id }) {
      seoFields {
        swbThemeDescription
        swbThemeMetaTitle
        swbThemeImage {
          localFile {
            relativePath
          }
        }
      }
    }

    hero: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_LibrarySpace {
          librarySpace {
            heroImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }
          }
        }
      }
    }

    intro: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_LibrarySpace {
          librarySpace {
            introTitle
            introBigContent
            introMainContent
          }
        }
      }
    }

    sliderLightbox: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_LibrarySpace {
          librarySpace {
            sliderSlides {
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 2500)
                  }
                }
              }
            }
          }
        }
      }
    }

    featuredImg: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_LibrarySpace {
          librarySpace {
            featuredImageImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }
          }
        }
      }
    }

    moreImformation: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_LibrarySpace {
          librarySpace {
            moreInformationLinksTitle
            moreInformationLinksButtons {
              buttonText
              linkType
              page {
                ... on WpPage {
                  id
                  slug
                  uri
                }
              }
              url
              sectionId
            }
          }
        }
      }
    }
  }
`

export default librarySpace
